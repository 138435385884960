import React, {useContext, useEffect, useState} from "react";
import {auth} from "../firebase";
import {
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
    updateEmail,
    updatePassword
} from "firebase/auth";

const AuthContext = React.createContext(null);

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({children}) {
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);

    function signUp(email, password) {

        return createUserWithEmailAndPassword(auth, email, password)
            .then((userCridentials) => {
                setCurrentUser(userCridentials.user);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function login(email, password) {
        return signInWithEmailAndPassword(auth, email, password)
            .then(resp => {
                localStorage.setItem('username', resp.user.email);
                console.log('Login successful! ', resp);
            })
            .catch((error) => console.log(error));
    }

    function logOut() {

        const message = signOut(auth)
            .catch(err => console.log(err))
        console.log(message);
        localStorage.clear();
        console.log('[*] Sign out successful!');
        return message;
    }

    function resetPassword(email) {
        return sendPasswordResetEmail(auth, email);
    }

    function updateEm(email) {
        return updateEmail(currentUser, email);
    }

    function updatePass(password) {
        updatePassword(currentUser, password)
            .then((res) => console.log(res)
            );
    }


    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setLoading(false);
            setCurrentUser(user);
        });
    }, []);

    const value = {
        currentUser,
        login,
        signUp,
        logOut,
        resetPassword,
        updateEm,
        updatePass,
    };

    return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
