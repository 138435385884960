import React, {useState} from "react";
import {Alert, Button, Card} from "react-bootstrap";
import {useAuth} from "../../contexts/AuthContext";
import {Link, useNavigate} from "react-router-dom";
import styles from '../../styles/layout.module.css';

export default function Dashboard() {
    const [error, setError] = useState("");
    const {
              currentUser,
              logOut
          } = useAuth();
    const navigate = useNavigate();

    async function handleLogOut() {
        setError("");

        try {
            await logOut();
            navigate("/login");
        } catch {
            setError("Failed to log out.");
        }
    }

    return (
        <div className={styles.mainContainer}>
            <Card className={styles.card} style={{

                borderRadius: '18px'
            }}>
                <Card.Body>
                    <h2 className="text-center mb-4">Profile</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <strong>Email: </strong> {currentUser.email}
                    <Link to="/update-profile" className="btn btn-primary w-100 mt-3"
                          style={{
                              background: 'var(--accent)',
                              border: '1px solid var(--accent'
                          }}>
                        Update profile
                    </Link>
                </Card.Body>
            </Card>
            <div className="w-100 text-center mt-2">
                <Button variant="link" onClick={handleLogOut} style={{
                    textDecoration: 'none',
                    color: "var(--accent"
                }}>
                    Log Out
                </Button>
            </div>
        </div>
    );
}
