import React, { Fragment, useState } from "react";
import styles from "../../styles/layout.module.css";
import ideaSeminarPic from "../../images/teaching/courses//ideaseminar/advancedScripting-cover.jpg";

export default function Teaching() {
  const [detailState, setDetailState] = useState(false);

  function handleDetails() {
    setDetailState((prev) => !prev);
  }

  return (
    <Fragment>
      <div className={styles.card} onClick={handleDetails}>
        <img src={ideaSeminarPic} alt="Idea Seminar" />
        <h2>Idea Seminar</h2>
        <p>
          {" "}
          School of Architecture
          <br />
          UNC Charlotte
          <br />
          Fall 2021{" "}
        </p>
      </div>

      {detailState ? (
        <div className={`${styles.card} ${styles.following}`}>
          <h2>Introduction</h2>
          <p style={{ textAlign: "justify" }}>
            {" "}
            INTRODUCTION Computer-Aided Drawing (CAD) software is now an
            inseparable tool for architects. While there are many advanced
            programs and computational tools out there, each program introduces
            various limitations. Scripting helps the user overcome these
            limitations by user-written programs. Scripting can also benefit the
            designer to explore information and obtain knowledge by working with
            and analyzing big data. In Parametric Architecture, scripting helps
            designers design the process, not the outcome, and enables them to
            control complexity beyond human capabilities. Some of the scripting
            applications in Architecture are: developing plug-ins for
            modeling/BIM software, generative design, form-finding, agent-based
            modeling, digital fabrication, and data-driven design. This course
            focuses on Object-Oriented Programming (OOP), one of the major
            programming paradigms. The premise of the course is to use OOP to
            built-up skill sets that are most needed to tackle future problems
            in the field or advanced research. The goal is to cultivate logical
            thinking in computational methods, develop algorithmic thinking, and
            implement object-oriented scripting throughout the course via a set
            of relative assignments and projects.
          </p>
        </div>
      ) : null}
    </Fragment>
  );
}
