import React, { useEffect, useState } from "react";
// import ContentForm from "../../components/blog/contentform/ContentForm";
import ContentHolder from "../../components/blog/contentHolder/ContentHolder";
import classes from "../../styles/layout.module.css";
import classes2 from "./Blog.module.css";
import Loading from "../../images/icons8-loading.png";
import ContentForm from "../../components/blog/contentform/ContentForm";
import { useAuth } from "../../contexts/AuthContext";

export default function Blog(props) {
  const { currentUser } = useAuth();
  //Loading state
  const [isLoading, setIsLoading] = useState(true);
  const [loadedPosts, setLoadedPosts] = useState([]);

  useEffect(() => {
    //Load data from DB
    fetch(
      `https://studiorogha-default-rtdb.firebaseio.com/blog/${currentUser.uid}.json`
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setLoadedPosts(data);
        setIsLoading(false);
      });
  }, [currentUser.uid]);

  if (isLoading) {
    return (
      <section>
        <img src={Loading} alt="Loading" className={classes.loading} />
      </section>
    );
  } else {
    const keys = Object.keys(
      loadedPosts || {
        title: "",
        subTitle: "",
        content: "",
        thumbnail: "",
        key: "",
        post_id: "",
        author_id: "",
        author: "",
      }
    );
    return (
      <div className={classes.mainContainer}>
        <div className={classes2.postsContainer}>
          {loadedPosts
            ? keys.map((k, i) => {
                const bPost = loadedPosts[k];
                return (
                  <ContentHolder
                    title={bPost.title}
                    subTitle={bPost.date}
                    content={bPost.content}
                    thumbnail={bPost.thumbnail}
                    key={i}
                    post_id={bPost.post_id}
                    author_id={bPost.author_id}
                    author={bPost.author}
                  />
                );
              })
            : "No posts yet!"}
        </div>
        <div>
          <ContentForm />
        </div>
      </div>
    );
  }
}
