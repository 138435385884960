import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./NavBar.module.css";
import { unAuthMenuItemsData, authMenuItemsData } from "./navbarData";
import { useAuth } from "../../../contexts/AuthContext";

export default function NavBar() {
  const [authenticated, setAuthenticated] = useState(false);
  const { currentUser } = useAuth();
  const userAuthentication = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setAuthenticated(currentUser && localStorage.getItem("username"));
  }, [currentUser]);

  const handleSignOut = () => {
    userAuthentication.logOut();
    setAuthenticated(false);
    localStorage.clear();
    navigate("/home");
  };

  const genLinks = () => {
    const itemsInMenu = authenticated ? authMenuItemsData : unAuthMenuItemsData;
    return itemsInMenu.map((info, i) => {
      return (
        <button
          key={`l${i}`}
          className={classes.navButton}
          style={
            info.name === "Sign out" ? { background: "var(--accent)" } : null
          }
          onClick={() => {
            if (info.name === "Sign out") {
              handleSignOut();
            }
            navigate(info.route);
          }}
        >
          <img src={info.iconB} alt={info.name} className={classes.iconB} />
          <img src={info.iconW} alt={info.name} className={classes.iconW} />
          {<span className={classes.label}>{info.name}</span>}
        </button>
      );
    });
  };

  const navClass = classes.menuBoxOn;
  return (
    <Fragment>
      <div className={navClass}>
        {currentUser ? (
          <div className={classes.userMessage}>
            <p>
              Welcome <span className={classes.user}>{currentUser.email}</span>
            </p>
          </div>
        ) : null}
        {genLinks()}
      </div>
    </Fragment>
  );
}
