import React from 'react';
import styles from "../../styles/layout.module.css";

function Research(props) {
    return (
        <div className={styles.subContainer}>
            {/*<div className={styles.card}>*/}
            {/*    <img src={ideaSeminarPic} alt="Idea Seminar"/>*/}
            {/*    <h2>Idea Seminar</h2>*/}
            {/*    <p> School of Architecture<br/>UNC Charlotte<br/>Fall 2021 </p>*/}
            {/*</div>*/}


        </div>)
}

export default Research;