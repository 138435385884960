import React from "react";
import classes from "../../styles/layout.module.css";
import profilePic from "../../images/profile_pic_s.jpg";

export default function Bio() {
  const styles = {
    p: {
      margin: 0,
    },
    tx1: {
      // display: 'flex',
      width: "75%",
      margin: "2% 0 0 20%",
      textAlign: "justify",
    },

    profile: {
      // position: 'relative',
      float: "left",
      left: "10%",
      // marginRight:'5%',
      width: "12%",
      minWidth: "50px",
      border: "3px solid rgba(150,150,150,1)",
      borderRadius: "50%",
      margin: "2%",
    },
  };

  return (
    <div
      className={classes.card}
      style={{
        width: "90%",
        margin: "auto",
      }}
    >
      <img src={profilePic} style={styles.profile} alt={"Milad Rogha"} />

      <p style={styles.tx1}>
        I am a Ph.D. student at the{" "}
        <a href={"https://viscenter.charlotte.edu/"}>
          {" "}
          Ribarsky Center for Visual Analytics
        </a>
        , Department of Computer Science, College of Computing and Informatics,
        University of North Carolina at Charlotte. I am a Ph.D. student at the
        Ribarsky Center for Visual Analytics, Department of Computer Science,
        College of Computing and Informatics, University of North Carolina at
        Charlotte. My research interests includes data visualization,
        explainable AI, and social computation. The rise in the application of
        artificial intelligence, especially in the fields requiring critical
        decision-making, raised questions regarding the autonomy and reliability
        of the predictions made by these models. My research focus is a twofold:
        How data visualization techniques can increase the efficiency and
        efficacy of AI-human decision-making? How do we convey the model results
        to lay users in such a way that it is persuasive?
      </p>

      <p></p>
    </div>
  );
}
