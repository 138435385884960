import React, {useRef, useState} from "react";
import {Alert, Button, Card, Container, Form} from "react-bootstrap";
import {useAuth} from "../../contexts/AuthContext";
import {Link} from "react-router-dom";

export default function Signup() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const {signUp} = useAuth();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    async function handleSignUp(e) {
        e.preventDefault();
        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError("Passwords do not match!");
        }
        try {
            setError("");
            await signUp(emailRef.current.value, passwordRef.current.value);
        } catch (er) {
            setError("Failed to create an account!");
        }
        setLoading(false);
    }

    return (
        <>
            <Container
                className="d-flex align-items-center justify-content-center"
                // style={{ minHeight: "100vh" }}
            >
                <div className="w-100" style={{maxWidth: "400px"}}>
                    <Card style={{borderRadius: '15px'}}>
                        <Card.Body>
                            <h2 className="text-center mb-4">Sign Up</h2>
                            {error && <Alert variant="danger">{error}</Alert>}
                            <Form onSubmit={handleSignUp}>
                                <Form.Group id="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" ref={emailRef} required/>
                                </Form.Group>
                                <Form.Group id="password">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" ref={passwordRef} required/>
                                </Form.Group>
                                <Form.Group id="password-confirm">
                                    <Form.Label>Password Confirmation</Form.Label>
                                    <Form.Control type="password" ref={passwordConfirmRef} required/>
                                </Form.Group>
                                <Button className="w-100" disabled={loading} type="submit" style={{
                                    height: 'fit-content',
                                    marginTop: '5%',
                                    background: 'var(--accent)',
                                    border: '0 solid white'
                                }}>
                                    Sign Up
                                </Button>
                            </Form>
                            <div className="w-100 text-center mt-2">
                                Already have an account? <Link to="/login" style={{
                                color: 'var(--accent',
                                fontWeight: 'bold'
                            }}>
                                Log In</Link>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </Container>
        </>
    );
}
