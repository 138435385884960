import React, {useRef, useState} from "react";
import {Alert, Button, Card, Container, Form} from "react-bootstrap";
import {useAuth} from "../../contexts/AuthContext";
import {Link, useNavigate} from "react-router-dom";

export default function SignIn() {
    const emailRef = useRef("");
    const passwordRef = useRef("");
    const {login} = useAuth();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    async function handleSignIn(e) {
        e.preventDefault();
        try {
            setError("");
            await login(emailRef.current.value, passwordRef.current.value);
            navigate("/dashboard");
        } catch (er) {
            setError("Failed to sign in!");
        }
        setLoading(false);
    }

    return (
        <>
            <Container
                className="d-flex align-items-center justify-content-center"
                // style={{ minHeight: "100vh" }}
            >
                <div className="w-100" style={{maxWidth: "400px"}}>
                    <Card style={{borderRadius: '15px'}}>
                        <Card.Body>
                            <h2 className="text-center mb-4">Log In</h2>
                            {error && <Alert variant="danger">{error}</Alert>}
                            <Form onSubmit={handleSignIn}>
                                <Form.Group id="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" ref={emailRef} required/>
                                </Form.Group>
                                <Form.Group id="password">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" ref={passwordRef} required/>
                                </Form.Group>
                                <Button className="w-100" disabled={loading} type="submit" style={{
                                    height: 'fit-content',
                                    marginTop: '5%',
                                    background: 'var(--accent)',
                                    border: '0 solid white'
                                }}>
                                    Log In
                                </Button>
                            </Form>
                            <div className="w-100 text-center mt-1">
                                <Link to="/forgot-password" style={{color: 'var(--accent'}}> Forgot password?</Link>
                            </div>
                            <div className="w-100 text-center mt-2">
                                Need an account? <Link to="/signup" style={{
                                color: 'var(--accent',
                                fontWeight: 'bold'
                            }}>Sign Up</Link>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </Container>
        </>
    );
}
