import React from "react";
import styles from "../../styles/layout.module.css";
import landingStyle from "./Landing.module.css";
import {
  news,
  aboutMe,
  researchInterests,
  publications,
} from "./landingData.jsx";

//images
import email from "../../images/social media/icons8-mail-100.png";
import profilePic from "../../images/profile_pic_s.jpg";
import gitHub from "../../images/social media/icons8-github.png";
import linkedIn from "../../images/social media/icons8-linkedin-circled-100.png";
import twitter from "../../images/social media/icons8-twitter-circled-100.png";
import googleScholar from "../../images/social media/icons8-google-scholar-100.png";

function Landing(props) {
  const publicationCards = publications.map((item, i) => (
    <div key={`paper${i}`}>
      <h3>
        {item.title}{" "}
        <span className={landingStyle.pubYear}>{" | " + item.year}</span>
        <span className={landingStyle.pubYear}>
          {" | "} <a href={item.link}>Link</a>{" "}
        </span>
      </h3>
      <p className={landingStyle.pubAuthors}>{item.authors}</p>
    </div>
  ));
  const reaserchCards = researchInterests.map((item, i) => (
    <div key={`research_card_${i}`}>
      <img src={item.image} alt={`research_interest_${i}`} />
      <h3>{item.title}</h3>
      <p>{item.description}</p>
    </div>
  ));
  const newsItems = news.map((item, i) => {
    return (
      <div className={landingStyle.news} key={`article_${i}`}>
        <div className={landingStyle.newsTitle}>
          <p className={landingStyle.tag}>{item.tag}</p>
          <p className={landingStyle.date}>{item.date}</p>
        </div>
        <div className={landingStyle.newsBody}>{item.body}</div>
      </div>
    );
  });
  return (
    <div className={styles.container}>
      <div className={landingStyle.informationContainer}>
        <div className={landingStyle.badge}>
          <div className={landingStyle.profileContainer}>
            <img
              src={profilePic}
              alt={"Milad Rogha"}
              className={landingStyle.profilePic}
            />
            <h2> Milad Rogha</h2>
          </div>
          <div className={landingStyle.badgeInfo}>
            <p>Ph.D. student</p>
            <p>Graduate Research Assistant </p>
            <p>Department of Computer Science</p>
            <p>UNC Charlotte</p>
          </div>
          <div className={landingStyle.socialMedia}>
            <a
              href={"https://linkedin.com/in/milad-rogha"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedIn} className={styles.icon} alt="LinkedIn" />
            </a>
            <a
              href={"https://github.com/miladrogha"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={gitHub} className={styles.icon} alt="GitHub" />
            </a>
            <a
              href={"https://twitter.com/MiladRogha"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitter} className={styles.icon} alt="Twitter" />
            </a>
            <a href={"mailto:mrogha [at] charlotte.edu"}>
              <img src={email} className={styles.icon} alt="Email" />
            </a>
            <a
              href={
                "https://scholar.google.com/citations?user=gk1iIxoAAAAJ&hl=en&oi=ao"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={googleScholar}
                className={styles.icon}
                alt="Google Scholar Profile"
              />
            </a>
          </div>
        </div>
        <div className={landingStyle.centerBody}>
          <div className={landingStyle.aboutMe}>
            <h2>About Me</h2>
            <p>{aboutMe.description}</p>
          </div>
          <div className={landingStyle.publications}>
            <h2>Publications</h2>
            <div className={landingStyle.publicationCards}>
              {publicationCards}
            </div>
          </div>
          <div className={landingStyle.researchInterests}>
            <h2>Research Interests</h2>
            <div className={landingStyle.reaserchCards}>{reaserchCards}</div>
          </div>
        </div>

        <div className={landingStyle.newsContainer}>
          <h2>News</h2>
          <div>{newsItems}</div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
