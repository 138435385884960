import React, {useEffect, useRef, useState} from "react";
import classes2 from "./ContentForm.module.css";
import classes from "../../../styles/layout.module.css"
import 'react-quill/dist/quill.snow.css';
import {AdminPanel} from "../adminpanel/AdminPanel"; // import styles
import {useAuth} from "../../../contexts/AuthContext";
import {v4 as uuidv4} from 'uuid';

export default function ContentForm(props) {


    const titleRef = useRef("");
    const contentRef = useRef("");
    const thumbnailRef = useRef("");
    const imageRef = useRef("");
    const [formMessage, setFormMessage] = useState(null);
    const {currentUser} = useAuth();

    // save data to DB
    function saveData(data) {
        currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
            fetch(`https://studiorogha-default-rtdb.firebaseio.com/blog/${currentUser.uid}.json?auth=${idToken}`, {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${idToken}`
                },
            })
                .then(resp => {
                        if (!resp.ok) {
                            alert('Error! Something went wrong!' || resp.message);
                            setFormMessage('Error! Something went wrong!');

                        } else {
                            alert('Posted!');
                            setFormMessage('Posted!');
                            setTimeout(() => {
                                setFormMessage(null);
                            }, 2000);


                        }
                    }
                );
        })
    }


    //on submit
    function submitHandler(event) {
        event.preventDefault();

        const enteredTitle = titleRef.current.value;
        const enteredContent = contentRef.current.value;
        const enteredThumbnail = thumbnailRef.current.value;
        const enteredImage = imageRef.current.value;

        const articleData = {
            title: enteredTitle,
            content: enteredContent,
            date: new Date().toLocaleDateString("en-US"),
            thumbnail: enteredThumbnail,
            images: enteredImage,
            post_id: uuidv4(),
            author_id: currentUser.uid,
            author: currentUser.email
        };

        if (currentUser) {
            saveData(articleData);
        } else {
            console.error('No user is authenticated');
            setFormMessage('No user is authenticated');
        }
    }

    useEffect(() => {
        // Example: Accessing Quill instance and setting content
        if (contentRef.current) {
            const quillInstance = contentRef.current.getEditor();
            quillInstance.setContents([
                {insert: "Hello "},
                {
                    insert: "World!",
                    attributes: {bold: true}
                },
            ]);
        }
    }, []);

    return (
        <div className={classes.mainContainer}>
            <div className={classes.notificationBox}>
                <p className={formMessage === 'Posted!' ? classes.notificationGreen : classes.notificationNeurtal}>{formMessage}</p>
            </div>
            <form onSubmit={submitHandler} className={classes2.formContainer}>
                <div className={classes.card}>
                    <h2>Content Form</h2>
                </div>

                <div className={classes2.formSubCont}>
                    <label htmlFor="title">Title:</label>
                    <input type="text" required id="title" ref={titleRef}/>
                </div>

                <div className={classes2.formSubCont}>
                    <label htmlFor="content">Content:</label>
                    {/*<textarea required id="content" ref={contentRef}/>*/}
                    <AdminPanel ref={contentRef} id={"content"}/>
                </div>

                <div className={classes2.formSubCont}>
                    <p>Thumbnail: </p>
                    <label htmlFor="thumbnail" className={classes2.customUpload}>
                        Select image
                        <input type="file" id="thumbnail" ref={thumbnailRef}/>
                    </label>
                    {thumbnailRef.current.value ? thumbnailRef.current.value : "Select an image"}
                </div>

                <div className={classes2.formSubCont}>
                    <p>Image: </p>
                    <label htmlFor="image" className={classes2.customUpload}>
                        Select image
                        <input type="file" id="image" ref={imageRef}/>
                    </label>
                    {imageRef.current.value ? imageRef.current.value : "Select an image"}
                </div>
                <div>
                    <button type="submit" className={classes2.buttonSubmit}>
                        Publish
                    </button>

                </div>
            </form>
        </div>
    );
}
