import classes from "./ContentHolder.module.css";
import React from "react";
import DOMPurify from 'dompurify';

function QuillOutput({content}) {
    const sanitizedContent = DOMPurify.sanitize(content);
    return <div dangerouslySetInnerHTML={{__html: sanitizedContent}}/>;
}

export default function ContentHolder(props) {
    return (
        <div className={classes.contentHolder}>
            <h2>{props.title}</h2>
            <h4>{props.subTitle}</h4>
            <QuillOutput content={props.content}/>
            <img src={props.thumbnail} alt=""/>
        </div>
    );
}
